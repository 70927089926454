<template>
  <div
    :class="chevron"
    style="position:fixed;z-index: 20; top: 50px"
    :style="{
      left: navWidth + 'px',
      'transition-duration': transitionSpeed + 's',
      transform: 'rotate(' + (isExpanded ? 0 : 180) + 'deg)',
    }"
    @click="togglenav"
  >
    <ChevronLeftIcon class="h-8 w-8 mx-auto text-slate-500" />
  </div>
  <div
    :class="leftnav"
    :style="{
      width: navWidth + 'px',
      
      'transition':'width ' + transitionSpeed + 's'
    }"
  >
    <!-- Navigation Routes -->
    <ul :class="navTabs" v-for="tab in nav" :key="tab.name">
      
        <li
          class="noselect transition"
          :class="selectedClass(tab)"
        >
          <router-link :to="'redirect' in tab ? tab.redirect : tab.path" class="nav-parent">
            <component :is="tab.icon" class="nav-icon h-6 w-6" />
            <span class="nav-text">{{ tab.name }}</span>
          </router-link>
        
          <!-- Router Children -->
          <ul
            class="nav-children"
            :style="{
              'max-height': isExpanded && tab.childDrop ? '33vh' : '0vh',
            }"
          >
            <li
              v-for="child in getTabChildren(tab)"
              :key="child.name"
              class="noselect"
              :class="selectedClassChild(child)"
            >
              <router-link
                :to="'redirect' in child ? child.redirect : child.path"
              >
                <div
                  class="nav-child-spacer"
                  style="transition-duration: 0.4s"
                  :style="{
                    'margin-left': isExpanded && tab.childDrop ? '0px' : '50px',
                  }"
                ></div>
                <!-- <component :is="tab.icon" class="nav-icon h-6 w-6" /> -->
                <span class="nav-child-text">{{ child.name }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      
    </ul>
  </div>
</template>



<script>
import { LifebuoyIcon,AdjustmentsVerticalIcon,ChevronLeftIcon,PlusIcon } from "@heroicons/vue/20/solid";
import { withNavPages } from "@/framework/routes/withNav.js";

export default {
  props: {
    navWidth: {
      type: Number,
      default: 0,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    transitionSpeed: {
      type: Number,
      default: 0,
    },
    navState: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      nav: withNavPages,
      leftnav:
        "navExpand fixed bg-primary left-0 top-0 pt-[100px] h-full drop-shadow-2xl z-[12] transition overflow-hidden duration-500 overflow-hidden",
      chevron:
        "absolute toggle-menu bg-white rounded-full w-[48px] h-[48px] " +
        "drop-shadow-md top-1/2 -m-[24px] right-0 cursor-pointer z-20 flex " +
        "items-center justify-items-center margin",
      navTabs:
        "mainnav text-left text-white font-extrabold tracking-widest",
    };
  },
  components: {
    LifebuoyIcon,
    AdjustmentsVerticalIcon,
    ChevronLeftIcon,
    PlusIcon,
  },
  methods: {
    selectedClass(tab) {
      const isSelected = tab.path == this.$route.path || tab.redirect == this.$route.path;
      return isSelected ? 'navItem active' : 'navItem';
    },
    selectedClassChild(child) {
      const isSelected = this.$route.path == child.path;
      return isSelected ? 'navItem active' : 'navItem';
    },
    leftNav(isExpanded) {
      var leftnav =
        "left-nav transition fixed bg-primary left-0 top-0 h-full drop-shadow-2xl ";
      leftnav += isExpanded ? "min-w-[260px]" : "min-w-[0px]";
      return leftnav;
    },
    togglenav() {
      this.$emit("emit_toggleNav", {});
    },
    navHover() {
      return this.isExpanded ? "8px" : "-1px";
    },
    isTabChildren(tab) {
      const path = this.$route.path.split("/").filter(Boolean);
      const name = tab.name.split(" ").join("").toLowerCase();
      return path.length > 1 && path.includes(name) && "children" in tab;
    },
    getTabChildren(tab) {
      if (!("children" in tab)) return [];
      const name = tab.name.split(" ").join("").toLowerCase();
      return tab.children.filter(function (item) {
        return item.name.split(" ").join("").toLowerCase() != name;
      });
    },
  },
  async created() {
    
    this.nav = this.nav.filter((tab) => "component" in tab); // valid components

    // Read Initial Nav State (refresh)
    const navState = this.$store.getters.navState;
    if (navState === undefined || navState === null)
      await this.$store.dispatch("mutation", ["navState", {}]);

    for (var i = 0; i < this.nav.length; i++) {
      const navName = this.nav[i].name;
      try {
        if (navName in navState) this.nav[i]["childDrop"] = navState[navName];
        else this.nav[i]["childDrop"] = false;
      } catch (error) {}

    }

    // Transition to New Nav State
    setTimeout(() => {
      var newNavState = {};
      for (var j = 0; j < this.nav.length; j++) {
        const newDrop = this.isTabChildren(this.nav[j]);
        this.nav[j].childDrop = newDrop;
        newNavState[this.nav[j].name] = newDrop;
      }
      this.$store.dispatch("mutation", ["navState", newNavState]);
    }, 100);



  },
};
</script>

<style scoped>


.navItem
{
  @apply uppercase block w-full transition;
}
.navItem a {  
  @apply flex w-full px-[10px] py-[20px] transition;
}
.nav-icon {
  @apply ml-[10px]
}
.nav-text {
  @apply whitespace-nowrap absolute left-[70px]
}

.navItem a:hover {
  @apply bg-secondary/50 
}
.nav-children .navItem {
  @apply normal-case font-normal 
}
.nav-children .navItem a{
  @apply text-sm text-right pl-[80px] py-3
}
.nav-children .navItem a:before{
  @apply text-sm text-right pl-[80px] py-3
}
.nav-children {
  @apply overflow-hidden duration-200
}
.navItem.active {
  @apply bg-secondary
}
.navItem:has(.nav-children .navItem.active) .nav-parent
{
  @apply bg-secondary/50 
}
</style>