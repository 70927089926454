<template>
  <router-view v-slot="{ Component, route }">
    <transition name="page-transition" mode="out-in">
      <div :key="route.name">
        <component :is="Component"></component>
      </div>
    </transition>
  </router-view>
  
</template>


<style>
.page-transition-enter-active .page-content,
.page-transition-leave-active .page-content {
  transition: 600ms ease all;
}
.page-transition-enter-from .page-content,
.page-transition-leave-to .page-content {
  opacity: 0;
}



</style>


<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  mounted() {
    
  },
};
</script>




