import { createRouter, createWebHistory } from 'vue-router';
import { noNavPages } from '@/framework/routes/noNav.js';
import { withNavPages } from '@/framework/routes/withNav.js';
import store from '@/framework/store';
import { getJwtToken } from '@/scripts/cognito';

var routes = [
  ...noNavPages,
  ...[
    // {
    //   // REMOVE BEFORE DEPLOYMENT!!!
    //   path: '/devtools',
    //   name: 'devtools',
    //   component: () => import("@/devtools/DevTools.vue"),
    // },
    {
      path: '/data-access',
      name: 'data-access',
      component: () => import("@/devtools/PublicAccess.vue")
    },
    {
      // REMOVE BEFORE DEPLOYMENT!!!
      path: '/websocket',
      name: 'websocket',
      component: () => import("@/devtools/websocket/WebSocket.vue"),
    },
    {
      path: '/',
      redirect: '/welcome',
      name: "Root",
      component: () => import("@/components/page/pagetemplate.vue"),
      children: withNavPages
    },
    {
      // Catch all Redirect
      path: '/:pathMatch(.*)*',
      redirect: "/not-found",
    }

  ],
  
]

const subdomain = 'beta'; // this probably works on the host ? todo
routes = window.location.host.split('.')[0] == subdomain ? routes : routes; // temp ignore
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  
  function expiredSession() {
    store.dispatch("mutation", ["loginExpired", true]);
    next({
      path: '/login',
      params: { nextUrl: to.fullPath }
    })
  }

  // Handle New Tab
  const isLoggedIn = store.getters.loggedinEmail;
  if (typeof isLoggedIn == 'undefined' || isLoggedIn == null || isLoggedIn == "") {
    store.dispatch("requestLocalStorage").then( (result) =>{
      if (!result) store.dispatch("clearLocalStorage");
    });
  }

  getJwtToken().then((thisToken) => {        
  
    if (to.matched.some(rts => rts.meta.requiresAuth)) {
      if (thisToken == null || thisToken == "") expiredSession();
      else next();
    } 
    else if (thisToken.length && to.path.toLowerCase() === '/welcome')
    {      
      next({
        path: '/mymeters'        
      });
    }
    else next();
  });
  
})

export default router
