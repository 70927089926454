import { LifebuoyIcon } from "@heroicons/vue/20/solid";
import { AdjustmentsVerticalIcon } from "@heroicons/vue/20/solid";
import { PlusIcon } from "@heroicons/vue/20/solid";

const meterPages = [
    {
        path: '/mymeters',
        name: 'My Meters',
        component: () => import("@/views/meters/mymeters/MyMeters.vue"),
        meta: {
            requiresAuth: true,
         },
    },
    {
        path: '/mymeters/managemeter',
        name: 'Manage Meter',
        component: () => import("@/views/meters/managemeter/ManageMeter.vue"),
        meta: {
            requiresAuth: true,
         },
    },

    {
        path: '/mymeters/managemeter/meterdata',
        name: 'All Meter Data',
        component: () => import("@/views/meters/meterdata/MeterData.vue"),
        meta: {
            requiresAuth: true,
         },
    },
    {
        path: '/mymeters/managemeter/newdashboard',
        name: 'New Dashboard',
        component: () => import("@/views/dashboards/new-dashboard.vue"),
        meta: {
            requiresAuth: true,
         },
    },
]

const dashboards = [
    {
        path: '/mymeters/managemeter/viewdashboard',
        name: 'View Dashboard',
        component: () => import("@/views/dashboards/view-dashboard.vue"),
        meta: {
            requiresAuth: true,
         },
    },
]

export var withNavPages = [
    {
        name: 'My Meters',
        path: '/MeterRoot',
        redirect: '/mymeters',
        component: () => import("@/views/meters/Meters.vue"),
        children: meterPages,
        icon: AdjustmentsVerticalIcon,
        meta: {
            requiresAuth: true,
         },
    },
    {
        name: 'Add Meter',
        path: '/add-meter',
        component: () => import("@/views/meters/addmeter/AddMeter.vue"),
        icon: PlusIcon,
        meta: {
            requiresAuth: true,
         },
    },
    {
        name: 'Support',
        path: '/support',
        component: () => import("@/views/support/support.vue"),
        icon: LifebuoyIcon,
        meta: {
            requiresAuth: true,
         },
    },

    {
        name: 'Dashboards',
        path: '/dashboards',
        children: dashboards,
        meta: {
            requiresAuth: true,
         },
    },

    
]
