<!--
    AUTHOR: Ryan Lang
    LAST UPDATE: 12/7/2022
    DESCRIPTION: Component to show the users avatar in the upper right of the top nav
    ADDITIONAL INFORMATION: Originally intended to be used to show account user's avatar. As of v1.0 not in use
-->
<template>
    <div class="avatar w-[64px] h-[64px] rounded-full bg-white flex items-center justify-center overflow-hidden">
        <h1 class="text-3xl text-slate-500 font-black opacity-50"><img src="/assets/user-icon.png" alt="UserIcon" /></h1>
    </div>
</template>