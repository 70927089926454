<template>
    <a  v-if="isExternal" :href="to"><slot/></a>
    <router-link v-else v-bind="$props"><slot /></router-link>
</template>

<script>
    import {RouterLink} from 'vue-router'
    export default{
      props:{ ...RouterLink.props }
    }
</script>