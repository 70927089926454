<!--
    AUTHOR: Ryan Lang
    LATEST UPDATED DATE: 12/13/2022
    DESCRIPTION: Dashboard widget/card that represents an accumulated value according to defined parameter and date/time range values
    ADDITIONAL INFORMATION: //
-->
<template>
   <Transition>
        <div
          
          class="
            mb-4
            mr-4
            block
            bg-white
            shadow-md   
            text-dark text-sm
            px-6
            py-4
            rounded-md
            transition
            relative
            hover:shadow-lg            
          "

          @mouseenter="showIcons = true"
            @mouseleave="showIcons = false"
          >
          <Transition :duration="1000">
                <div class="absolute top-2 right-2" v-if="showIcons">
                  <Button
                    class="bg-slate-200 text-slate-400 transition hover:bg-slate-400/80 hover:text-slate-50 px-2 py-2 mr-1"
                    @click="loadAndProcessDashboard()"
                    ><ArrowPathIcon class="h-5 w-5"/> </Button>
                    <Button
                    class="bg-slate-200 text-slate-400 transition hover:bg-slate-400/80 hover:text-slate-50 px-2 py-2"
                    @click="openDeleteModal"
                    ><TrashIcon class="h-5 w-5"/> </Button>
                </div>
            </Transition>
          <div class="h-full flex flex-col justify-between items-center text-center font-bold text-lg">
            
            <div>{{ Settings.FullName }} </div>
            <div class="text-xs font-normal mb-4">{{ Settings.Comment }}</div>
            <div v-if="dataLoading" class="btn-loader w-10 h-10">
            
            </div>
            <div v-else >
                <Transition>
                    <span v-if="noData"  class="text-xl text-slate-300">
                            No Data
                    </span>
                    <span v-else >
                        <div class="text-5xl text-slate-500">
                          {{ returnValue }}
                        </div>
                        <div class="text-sm text-slate-500">
                          {{dashboardUom}}
                      </div>
                    </span>
                    
            </Transition>
            </div>

            <div v-for="(param,index) in Settings.Parameters" :key="param.param" class="justify-self-end border border-slate-200 bg-white shadow-md rounded p-2 mt-4 pl-8 mb-2">
              <span class="param-name mr-4 text-sm" v-if="param.displayname">
              {{param.displayname}}
              </span> 
              <span class="param-name mr-4 text-sm" v-else>
              {{param.param}}
              </span>              
            </div>

          </div>
        </div>
    </Transition>


    <!-- Confirm delete dash -->

    <GDialog v-model="isDeleteModalOpen">      
      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center">

            <div
              class="
                w-full
                max-w-2xl
                transform
                overflow-hidden
                rounded-lg
                bg-white
                p-6
                text-left
                align-middle
                shadow-xl
                transition-all
              "
            >
              <h3 class="text-lg font-bold leading-6 text-gray-900"
              >
                CONFIRM DELETE
        </h3>

              <div class="mt-8">
                <h2 class="font-bold mb-2">Are you sure?</h2>
                <p class="text-sm mb-4">
                  This action will remove this dashboard from your account.
                </p>

                <div class="text-alert text-left text-xs mb-2" v-if="showDeleteError"><ExclamationTriangleIcon class="inline h-6 w-6"/> Problem removing dashboard.</div>

                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    bg-primary
                    px-4
                    py-2
                    text-sm
                    font-medium
                    text-white
                    mr-4
                    transition
                    hover:bg-primary/80
                    focus:outline-none
                    focus-visible:ring-2
                    focus-visible:ring-blue-500
                    focus-visible:ring-offset-2
                  "
                  @click="confirmDelete"
                >
                <span v-if="showDeleteLoading" class="loading">One moment <span class="btn-loader"></span></span>
                <span v-else>Confirm</span> 
                  
                </button>
                <button
                  type="button"
                  class="
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    bg-blue-100
                    px-4
                    py-2
                    text-sm
                    font-medium
                    transition
                    text-blue-900
                    hover:bg-blue-200
                    focus:outline-none
                    focus-visible:ring-2
                    focus-visible:ring-blue-500
                    focus-visible:ring-offset-2
                  "
                  @click="closeDeleteModal"
                >
                  Cancel
                </button>
              </div>
            </div>
        </div>
      </div>
    </GDialog>

</template>

<script setup>

/****external imports ****/
import { ref, computed, onMounted,toRaw } from "vue";
import { useStore } from 'vuex' 
import { format,parseISO,addHours } from 'date-fns';
import {ArrowPathIcon, ExclamationTriangleIcon,TrashIcon} from '@heroicons/vue/20/solid'
import { meterParameterOptions } from '@/views/dashboards/dashboardConfig.js';
import {toUTCDate, convertUTCDateToLocalDate} from '@/scripts/dateHelpers'
/****external imports ****/

//component property definitions
const props = defineProps({
  Name: String,  
  Settings: Array
});

//reactive variable/object definitions
const store = useStore();
const meter = computed(() => {return store.getters.meter});
const userName = computed(() => {return store.state.loggedinEmail});
const dataLoading = ref(true);
const showDeleteLoading = ref(false);
const isDeleteModalOpen = ref(false);
const showIcons = ref(false);
const noData = ref(false);
const returnValue = ref();
const dashboardUom = ref('');

//emit definitions
const emit = defineEmits(['deleteDashboard']);

const confirmDelete = (event) => {

    const thisDash = {};
    thisDash['Name'] = props.Name;
    thisDash['Settings'] = props.Settings;

    emit('deleteDashboard', thisDash);

}


//returns the start date/time value of the dashboard time interval
function getTimeIntervalStart(){

    //type should be "value"

    if(props.Settings.TimeInterval) //predefined time intervals shown to end user in user interface
    {
        if(props.Settings.TimeInterval == 'current')  
        {
            return addHours(new Date(),-24).toISOString();
        }
        else if(props.Settings.TimeInterval == 'lastmonth')
        {
            //get last month
            let today = new Date();
                               
            const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);                        

            return addHours(lastDayPrevMonth,-24).toISOString();
        }
        else if(props.Settings.TimeInterval == 'lastyear')
        {
            //get last year
            let today = new Date();
                               
            const lastDayPrevYear = new Date(today.getFullYear(), 0, 0);                                    
            return addHours(lastDayPrevYear,-24).toISOString();
            
        }
    }
    else  
        return props.Settings.CustomTimeIntervalStart;  //custom time intervals specified by end user

}


//returns the ending date/time value of the dashboard time interval
function getTimeIntervalEnd(){

    if(props.Settings.TimeInterval)
    {
        if(props.Settings.TimeInterval == 'current')
        {
            return new Date().toISOString();
        }
        else if(props.Settings.TimeInterval == 'lastmonth')
        {
            //get last month
            let today = new Date();                               
            const lastDayPrevMonth = new Date(today.getFullYear(), today.getMonth(), 0);                        
            return lastDayPrevMonth.toISOString();
        }
        else if(props.Settings.TimeInterval == 'lastyear')
        {
            //get last year
            let today = new Date();                               
            const lastDayPrevYear = new Date(today.getFullYear(), 0, 0);                                         
            return lastDayPrevYear.toISOString();
            
        }
    }
    else 
        return props.Settings.CustomTimeIntervalEnd;        
    
}

//toggles the delete confirmation dialog box
function closeDeleteModal(){
    isDeleteModalOpen.value = false;
}

//toggles the delete confirmation dialog box
function openDeleteModal(){
    isDeleteModalOpen.value = true;
}

//reutrns array of parameters to be used in api call
function getParams(paramObj){

let params = [];
for (var p of paramObj) params.push(p.param);

return params;  
}

//loads the data 
async function loadDashboardData(){
    
    let returnVal = [];
    let thisParams = getParams(props.Settings.Parameters);
          
    let thisStartDateTime = parseISO(getTimeIntervalStart());
    let thisStartDateTimeUTC = toUTCDate(thisStartDateTime);

    let thisEndDateTime = parseISO(getTimeIntervalEnd());
    let thisEndDateTimeUTC = toUTCDate(thisEndDateTime);

    let thisStartDate = format(thisStartDateTimeUTC, "yyyy-MM-dd");
    let thisStartTime = format(thisStartDateTimeUTC, "HH") + ":" + format(thisStartDateTimeUTC, "mm");
    
    let thisEndDate = format(thisEndDateTimeUTC, "yyyy-MM-dd");
    let thisEndTime = format(thisEndDateTimeUTC, "HH") + ":" + format(thisEndDateTimeUTC, "mm");

    var params = {
    request: "getMeterRange",
    username: userName.value,
    metername: meter.value.name,
    range: { 
        startDate: thisStartDate, 
        startTime: thisStartTime, // UTC TIME!
        endDate: thisEndDate, 
        endTime: thisEndTime // UTC TIME!
    },
    topics: thisParams, // projection expression
    };
        
    console.log(params);
    let thisr = await store.dispatch("dentcloud_API", params);

    if(thisr.success)
      returnVal.push(thisr.table.rows);        
          

    return returnVal;

}


//process the returned data to be shown in the ui
function procData(params, arr){     

  let dataArr = [];
  
  let thisParam = params[0].param;    
  dashboardUom.value = params[0].uom;

  (arr).map((el, index) => {
       
      const obj = {};

      let paramVal = parseFloat(el[thisParam]);

      let thisDateStr = el.date + 'T' + el.time + ':00Z';
      
      let thisDtUTC = new Date(thisDateStr);

      let localDt = convertUTCDateToLocalDate(thisDtUTC);

        obj['time'] = format(localDt,"Pp");
        obj[thisParam]=paramVal;
        

        if(!Number.isNaN(paramVal))
        {
          dataArr.push(obj);
        }
              
    }); 
            
    //sort the data to get the latest value
    let returnArr = dataArr.filter(i => i != null).sort(function(a,b){    
      let thisDateA = new Date(a.time);
      let thisDateB = new Date(b.time);
      if (thisDateA < thisDateB) {
        return -1; // a comes before b
      } else if (thisDateA > thisDateB) {
        return 1; // a comes after b
      } else {
        return 0; // a and b are equal
      }
    });
    
    //return the latest value
    if(returnArr[0])
        return returnArr[0][thisParam]; 
    
}

//initial call to load the component contents
function loadAndProcessDashboard(){
    dataLoading.value = true;
    loadDashboardData().then(res => {
      
        let gData = procData(props.Settings.Parameters, res[0]);      

        if(gData)
            returnValue.value = gData;
        else
            noData.value = true;
        
        dataLoading.value = false;

    });
}

onMounted(() => {

  //call on load
  loadAndProcessDashboard();

});

</script>

<style>

</style>