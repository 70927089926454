<template>
    <header class="fixed topnavbar w-full bg-secondary h-[100px] z-[11] flex flex-col items-end justify-center">
        
        
        <Popover v-slot="{ open }">
            <PopoverButton>
                <button class="bg-white/20 hover:bg-white/40 transition rounded-full py-1 px-2 sm:px-4 flex justify-center items-center mr-2 sm:mr-4">
                    <span class="block p-1 sm:p-3 text-white text-xs sm:text-sm">Hello!</span>
                <ChevronDownIcon class="h-8 w-8 text-white transition duration-300" :class="{ ' rotate-180 transform': open }" />
            </button>
            </PopoverButton>
            
                <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
            <PopoverPanel class="profile-menu absolute z-20 right-[60px] top-[110px] bg-primary text-white text-left rounded-md  shadow-lg">
            <ul class="text-sm rounded-md">

                <li class="px-4 transition hover:bg-secondary hover:rounded-md"><RouterLink to="/forgot" class="block p-4"><UserCircleIcon class="h-6 w-6 inline pr-2 text-white transition" /> Reset Password</RouterLink></li>
                <li class="px-4 transition hover:bg-secondary hover:rounded-md "><button class="bg-transparent block p-4" @click="logOut"><ArrowRightOnRectangleIcon class="h-6 w-6 inline pr-2 text-white transition" />Log Out</button></li>
                
            </ul>
            
            </PopoverPanel>
        </transition>
        </Popover>
        
        
    </header>
</template>



<script setup>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon, UserCircleIcon, ArrowRightOnRectangleIcon } from "@heroicons/vue/20/solid";
const router = useRouter()   
const store = useStore()

async function logOut() {

    console.log('Logging out...')

try{
    const res = await store.dispatch("cognito_signOut");
    console.log(res.signOut);
    if(res.signOut)
        router.push('/');
}
catch{

}

};


</script>

<style>
.profile-menu:before {
    content:"";
    position: absolute;
    right: 11px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #012C52 transparent;
    z-index:20;
}
</style>