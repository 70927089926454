import { createApp } from 'vue/dist/vue.esm-bundler';

import App from '@/App.vue'
const app = createApp(App);

// storage and navigation
import router from '@/framework/router.js'
import store from '@/framework/store.js'

//styles
import './styles/app.css'; // tailwind
import 'gitart-vue-dialog/dist/style.css'

/**General Element components */
import DentLogo from "@/components/elements/vdentlogo.vue";
import DentCloudLogo from "@/components/elements/vdentcloudlogo.vue";
import AppLink from "@/components/elements/vapplink.vue";
import Button from "@/components/elements/vbutton.vue";
import Input from "@/components/elements/vtextinput.vue";
import PageLoading from "@/components/elements/vpageloading.vue";
import PulseIcon from "@/components/elements/vpulseicon.vue";
// main.js

import { GDialog } from 'gitart-vue-dialog'

/* Nav components */
import TopNav from "@/components/nav/topnav.vue";
import LeftNav from "@/components/nav/leftnav.vue";
import ProfileAvatar from "@/components/auth/vprofilelink.vue";

/* Page Template components */
import PageTemplate from "@/components/page/pagetemplate.vue";
import Breadcrumb from "@/components/elements/vbreadcrumb.vue";

/*Dashboard components */
import AccumulatedValueDashboard from "@/components/dashboard/vvaluedashboarddata.vue";

app.config.warnHandler = () => null; // NOTE: Disables all annoyances
app.component("DentLogo", DentLogo)
.component("AppLink", AppLink)    
.component("DentCloudLogo", DentCloudLogo)
    .component("TopNav", TopNav)
    .component("Button", Button)
    .component("LeftNav", LeftNav)
    .component("Input", Input)
    .component("PageTemplate", PageTemplate)
    .component("ProfileAvatar", ProfileAvatar)
    .component("Breadcrumb", Breadcrumb)
    .component("PageLoading", PageLoading)
    .component("AccumulatedValueDashboard", AccumulatedValueDashboard)
    .component("GDialog", GDialog)
    .component("PulseIcon",PulseIcon)

app.use(store).use(router).mount('#app')
