
  export function toUTCDate(date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  }
  
  export  function convertUTCDateToLocalDate(date) {    
  
    // Format the date as a string in the local time zone
    var localTimeString = date.toLocaleString();

    // Create a new date object from the local time string
    var localTime = new Date(localTimeString);
  
    return localTime;
  }
  
  var getvalidDate = function(d){ return new Date(d) }
  
  export  function validateDateBetweenTwoDates(fromDate,toDate,givenDate){
      return getvalidDate(givenDate) <= getvalidDate(toDate) && getvalidDate(givenDate) >= getvalidDate(fromDate);
  }
  