<template>
  <div
    class="text-slate-400 flex"
    style="margin-left: 10px; margin-bottom: 20px"
  >
    <div class="breadcrumb" v-for="route in breadRoutes" :key="route.path">
      <RouterLink :to="route.path" class="breadcrumb-item">{{
        route.name
      }}</RouterLink>
    </div>
  </div>
</template>

<script>
import { HomeIcon } from "@heroicons/vue/20/solid";

export default {
  data() {
    return {
      breadRoutes: [],
    };
  },
  created() {
    const routes = this.$router.options.routes;
    const paths = this.getAllRoutes(routes);

    var breadRoutes = [];
    const myRoutePath = this.$route.path.split("/").filter(Boolean);

    for (var r = 0; r < paths.length; r++) {
      if (paths[r].path == "/") continue;
      for (var i = 0; i < myRoutePath.length; i++) {
        var route = "";
        for (var p = 0; p <= i; p++) route += "/" + myRoutePath[p];
        if (paths[r].path == route) breadRoutes.push(paths[r]);
      }
    }

    this.breadRoutes = breadRoutes;
  },
  components: {
    HomeIcon,
  },
  methods: {
    getAllRoutes(routes) {
      var paths = [];
      for (var i = 0; i < routes.length; i++) {
        paths.push(routes[i]);
        if ("children" in routes[i])
          paths = paths.concat(this.getAllRoutes(routes[i].children));
      }
      return paths;
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentRoutePath() {
      return this.$route.path;
    },
  },
};
</script>

<style>
.breadcrumb-item {

}
.breadcrumb-item.router-link-active{
  font-weight: bold;
  cursor: default;
  
}
.breadcrumb:not(:last-child):after {
  display: inline;
  content: "/";
  padding: 0 8px;
}
.breadcrumb-item:hover:not(.router-link-active) {
  @apply text-textlink
  
  /* margin-right: -6.5px; */
}
</style>
