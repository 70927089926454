const AMPLIFY_AWS_CONFIG_REGION = process.env.VUE_APP_AWS_CONFIG_REGION;
const AMPLIFY_IDENTITY_POOL_ID = process.env.VUE_APP_IVUE_APPITY_POOL_ID;
const AMPLIFY_USER_POOL_ID = process.env.VUE_APP_USER_POOL_ID;
const AMPLIFY_USER_POOL_WEB_CLIENT_ID = process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID;
const AMPLIFY_MQTT_ID = process.env.VUE_APP_MQTT_ID;

// https://docs.amplify.aws/lib/storage/getting-started/q/platform/js/#configure-your-application
import { Amplify, Auth, Storage, PubSub } from 'aws-amplify';
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";

import store from '@/framework/store'
import router from '@/framework/router'

var subscription = []; // Websocket subscriptions

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: AMPLIFY_AWS_CONFIG_REGION,
    identityPoolId: AMPLIFY_IDENTITY_POOL_ID,
    userPoolId: AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  },
  Storage: { AWSS3: { isObjectLockEnabled: true } },
  Analytics: { disabled: true },
});
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: AMPLIFY_AWS_CONFIG_REGION,
    aws_pubsub_endpoint: `wss://${AMPLIFY_MQTT_ID}-ats.iot.${AMPLIFY_AWS_CONFIG_REGION}.amazonaws.com/mqtt`,
  })
);
Storage.configure({ region: AMPLIFY_AWS_CONFIG_REGION });
const AWSS3TestBucket = { bucket: 'dentcloud.testbucket.or', level: 'private' };
const AWSS3MeterDataBucket = { bucket: 'dentcloud.meterdata.or', pageSize: 1000 };
const AWSS3PublicData = { bucket: 'dent.data.access', pageSize: 1000 };

// Websocket subscription event initialize
export async function startPubSub(eventName, topic) {
  await Auth.currentCredentials().then((creds) => {

    const newSubscription = PubSub.subscribe(topic).subscribe({
      next: (data) => {
        var myEvent = new CustomEvent(eventName, {
          detail: data.value,
          topic: topic,
          credentials: creds,
          data: data, // out of scope on call?
        });
        document.dispatchEvent(myEvent);
      },
      error: (error) => console.error(error),
      close: () => console.log("Closed from some source..."),
    });
    subscription.push(newSubscription);
  });
  return;
}


// Unsubscribte to all Websocket events
export function unSubscribe() {
  if (subscription != []) {
    for (var i = 0; i < subscription.length; i++)
      subscription[i].unsubscribe();
    subscription = [];
  }
}


// Publish a message to the topic on a Websocket
export async function publishMsg(topic, message) {
  return await PubSub.publish(topic, message);
}


// Request a new Account to be email authorized
export async function signUp(email, username, password) {
  try {
    const { user } = await Auth.signUp({
      username: username,
      password: password,
      attributes: {
        email: email,
      },
    });
    return { success: true, username: username };
  } catch (error) {
    return { success: false, error: error };
  }
}


// Debug logIn only
export async function logIn() { return await SignIn("newuser69", "newpassword69"); }

// Sign into the authenticated account and acquire token
export async function signIn(username, password) { return await SignIn(username, password); }
async function SignIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    const jwtToken = await getJWTToken();
    return { success: true, user: user, token: jwtToken };
  } catch (error) {
    return { error: error, success: false, token: jwtToken };
  }
}


// Sign Out of account
export async function signOut() {
  try {
    await Auth.signOut();
    return true;
  } catch (error) { return false; }
}


// Use email code to confirm the account
export async function confirmSignUp(username, code) {
  try {
    const confirm = await Auth.confirmSignUp(username, code);
    if (confirm == "SUCCESS") return true;
    else return false;
  } catch (error) { return false; }
}


export async function resendConfirmationCode(username) {
  try {
    await Auth.resendSignUp(username);
    return { success: true };
  } catch (err) {
    return { success: false, error: err };
  }
}

// Get web token for the current account
async function getJWTToken() { return await getJwtToken(); }
export async function getJwtToken() {
  try {
    const session = await Auth.currentSession();
    const JWT = session.getIdToken().getJwtToken();
    return JWT;
  } catch (error) {
    console.log("error getting token: ", error);
    return "";
  }
}


// Request a change to the User's Password (Logged In)
// export async function changePassword(oldPassword, newPassword) {
//   Auth.currentAuthenticatedUser().then(user => {
//     return Auth.changePassword(user, oldPassword, newPassword);
//   })
//     .then(data => console.log('changePassword data ???', data))
//     .catch(err => console.log('changePassword error', err));
// }


// Request a Password change code to email
export async function forgotPassword(username) {
  const result = await Auth.forgotPassword(username)
    .then(data => {
      console.log('forgotPassword data', data);
      return { success: true, data: data };
    })
    .catch(err => {
      console.log('forgotPassword error', err)
      return { success: false, error: err };
    });
  return result;
}


// Use code to confirm new Password (old password not required)
export async function forgetPasswordSubmit(username, code, newPassword) {
  const result = await Auth.forgotPasswordSubmit(username, code, newPassword)
    .then(data => {
      console.log('forgotPasswordSubmit data', data);
      return { success: true, data: data };
    })
    .catch(err => {
      console.log('forgotPasswordSubmit error', err)
      return { success: false, error: err };
    });
  return result;
}


// check if user token is expired or rejected
export async function parseResponse(res) {

  if ("success" in res) return res;
  else // auth expired
    if (res.statusCode == 401 || res.statusCode == 403) {
      const signout = await store.dispatch("cognito_signOut");
      store.dispatch("mutation", ["loginExpired", true]);
      router.push('/login'); // redirect to login
    }
}

export async function s3_PUT(filename = 'default.txt', content = 'noContent') {
  const username = store.getters.loggedinEmail;

  return await Storage.put(username + '/' + filename, content, AWSS3TestBucket)
    .then(result => { console.log(result); return true }) // {key: "test.txt"}
    .catch(err => { console.log(err); return false });
}

export async function s3_GET(filename = 'default.txt') {
  const username = store.getters.loggedinEmail;

  const result = await Storage.get(username + '/' + filename, AWSS3TestBucket)
    .then(result => { return result }) // {key: "test.txt"}
    .catch(err => { return false });
  if (result)  // url to s3 endpoint
    return await fetch(result).then(response => { return response.text() });
  else return false;
}


export async function s3_DATA(params) {
  const fileRequests = get_fileRequests(params);

  var resultList = [];
  for (const request of fileRequests) {
    const result = await Storage.get(request, AWSS3MeterDataBucket)
      .then(result => { return result }) // {key: "test.txt"}
      .catch(err => { return false });
    if (result) resultList.push(result);
  }

  var dataset = { headers: [], table: [] };  // return in table parse form
  for (const result of resultList) {
    try {
      const response = await fetch(result)
      if (response.status === 200) {
        const responseText = await response.text();
        const parseSet = parse_csvMeterData(responseText);
        dataset.headers = Array.from(new Set([...dataset.headers, ...parseSet.headers]));
        dataset.table = dataset.table.concat(parseSet.table);
      }
    } catch (error) { };
  }

  const startDate = `${params.startDay} ${params.startTime}`; 
  const endDate = `${params.endDay} ${params.endTime}`; 
  function isBetweenDateTime(dateTime, startDateTime, endDateTime) {
    return dateTime >= startDateTime && dateTime <= endDateTime;
  }
  dataset.table = dataset.table.filter(item => {
    const itemDateTime = `${item.date} ${item.time}`;
    return isBetweenDateTime(itemDateTime, startDate, endDate);
  });
  return dataset;
}

export async function S3_public(params) {
  const metername = params.metername;
  const year = params.year;
  var fileList = await Storage.list(metername + '/' + year, AWSS3MeterDataBucket);
  // console.log(fileList);
  return fileList;

}


function get_fileRequests(params) {
  const metername = params.metername;
  const startDate = new Date(params.startDay);
  const endDate = new Date(params.endDay);

  const dataByMonth = {};  // parse the range to optimize requests
  while (startDate <= endDate) {
    const year = startDate.getUTCFullYear();
    const month = startDate.getUTCMonth(); // Zero-based index of the month
    const monthName = (new Date(Date.UTC(0, month, 1))).toLocaleString('en-US', { month: 'long', timeZone: 'UTC' });
    const dateKey = startDate.toISOString().slice(0, 10);

    if (!dataByMonth[year]) { dataByMonth[year] = {}; }
    if (!dataByMonth[year][monthName]) { dataByMonth[year][monthName] = []; }
    dataByMonth[year][monthName].push(dateKey);

    startDate.setUTCDate(startDate.getUTCDate() + 1);
  }

  var requests = [];
  for (const year in dataByMonth) {
    const yearLength = Object.keys(dataByMonth[year]).length;
    // if (yearLength > 6) { requests.push(year + '-year'); continue; } // year is too big...
    for (const month in dataByMonth[year]) {
      const monthLength = Object.keys(dataByMonth[year][month]).length;
      if (monthLength > 9) { requests.push(`${year}/${month}/${year}-${month}.csv`); continue; }
      for (const day in dataByMonth[year][month])
        requests.push(`${year}/${month}/day/${dataByMonth[year][month][day]}.csv`);
    }
  }
  // console.log(requests.map(str => `${metername}/${str.toLowerCase()}`));
  return requests.map(str => `${metername}/${str.toLowerCase()}`);
}


export async function s3_DATA_old(metername, dayRange) {
  // TODO: Account for today values (not in bucket)
  // TODO: Validate eventbridge automation
  // TODO: Pitch potential store updates instead of returning value

  const dayList = new Set(get_daysInRange(dayRange));
  var yearList = [];  // directories, metername/year/yyyy-mm-dd.csv
  for (const day of dayList) {
    const year = day.split('-')[0];
    if (!(yearList.includes(year))) yearList.push(year);
  }
  var requestList = [];  // validate s3 and check for requested range
  for (const year of yearList) {
    var meterDays = await Storage.list(metername + '/' + year, AWSS3MeterDataBucket);
    for (const path of meterDays.results) {
      const pathKey = ((path.key).split('/').pop().split('.'))[0];
      if (dayList.has(pathKey)) requestList.push(path.key);
    }
  }
  var resultList = [];  // get the actual s3 locations on web
  for (const request of requestList) {
    const result = await Storage.get(request, AWSS3MeterDataBucket)
      .then(result => { return result }) // {key: "test.txt"}
      .catch(err => { return false });
    resultList.push(result);
  }
  var dataset = { headers: [], table: [] };  // return in table parse form
  for (const result of resultList) {
    if (result) {
      const start = performance.now();  // DELETE: performance only!
      const response = await fetch(result).then(response => { return response.text() });
      const parseSet = parse_csvMeterData(response);
      dataset.headers = Array.from(new Set([...dataset.headers, ...parseSet.headers]));
      dataset.table = dataset.table.concat(parseSet.table);
      console.log('get data:', parseSet.table[0].date, (performance.now() - start).toFixed(3), 'ms'); // DELETE: performance only!
    }
  }
  return dataset;
}

function get_daysInRange(dayRange) {
  const [start, end] = (dayRange[0] <= dayRange[1]) ? dayRange : dayRange.reverse();

  const dateArray = [];
  const currentDate = new Date(start);
  while (currentDate <= new Date(end)) {
    dateArray.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dateArray;
}

function parse_csvMeterData(data) {
  const dataset = { headers: [], table: [] };
  const lines = data.trim().split('\n');
  const headerRow = lines[0].split(',');
  dataset.headers = headerRow; // Extract headers

  for (let i = 1; i < lines.length; i++) {
    const row = lines[i].split(',');
    const rowObject = { date: row[0], time: row[1] };
    for (let j = 0; j < row.length; j++) rowObject[headerRow[j]] = row[j];
    dataset.table.push(rowObject);
  };
  return dataset;
}